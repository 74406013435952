import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { CurrentNavigation } from '@parashift/shared/models';

@Injectable({
  providedIn: 'root'
})
export class CurrentNavigationService {

  private _navigation = new BehaviorSubject<CurrentNavigation>({ title: '', items: [] });
  navigation$ = this._navigation.asObservable();

  private _navigationChanged = new BehaviorSubject<boolean>(false);
  navigationChanged$ = this._navigationChanged.asObservable();

  private navigation: CurrentNavigation;

  emitNavigationChange() {
    this._navigationChanged.next(true);
  }

  getNavigation(): Observable<CurrentNavigation> {
    return of(this.navigation);
  }

  getPlainNavigation(): CurrentNavigation {
    return this.navigation;
  }

  setNavigation(currentNavigation: CurrentNavigation) {
    this.navigation = currentNavigation;
    this._navigation.next(this.navigation);
  }

  refreshNavigation() {
    this._navigation.next(this.navigation);
  }
}
