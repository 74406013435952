import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ListFilter, Tenant, User } from '@parashift/shared/models';
import { Permissions } from '@parashift/shared/constants';
import { TenantService } from '../airbrush/tenant.service';
import { SessionService } from '../session.service';
import { CurrentNavigationService } from '../current-navigation.service';

@Injectable({
  providedIn: 'root'
})
export class CurrentTenantRegister {
  private _tenant$ = new BehaviorSubject<Tenant>(undefined);
  tenant$ = this._tenant$.asObservable();
  get tenant() { return this._tenant$.getValue(); }
  set tenant(value) { this._tenant$.next(value); }

  public users: User[] = [];
  public plainUsers = [];

  private listFilter = new ListFilter({ include: 'roles,roles.user,roles.user.avatar_attachment,logo_attachment' });

  constructor(
    private tenantService: TenantService,
    private sessionService: SessionService,
    private currentNavigationService: CurrentNavigationService
  ) {}

  getTenant(tenant_id: string): Observable<boolean> {
    if (!tenant_id) {
      this.tenant = undefined;
      return of(false);
    }
    if (this.tenant && Number(this.tenant.id) === Number(tenant_id)) {
      return of(true);
    } else {
      return this.loadTenant(tenant_id)
        .pipe(
          map(() => true),
          catchError(() => of(false))
        );
    }
  }

  setTenant(tenant: Tenant, refresh = false) {
    this.tenant = tenant;
    this.setUsers();
    if (refresh) {
      this.currentNavigationService.refreshNavigation();
    }
  }

  loadTenant(tenant_id: string): Observable<Tenant> {
    this.tenant = undefined;
    return this.tenantService.findRecord(tenant_id, this.listFilter)
      .pipe(
        tap((tenant: Tenant) => {
          this.tenant = tenant;
          this.setUsers();
          this.currentNavigationService.refreshNavigation();
          this.currentNavigationService.emitNavigationChange();
        })
      );
  }

  saveAndSetTenant(tenant: Tenant): Observable<Tenant> {
    return this.tenantService.saveRecord(tenant)
      .pipe(
        switchMap(tenant => {
          return this.tenantService.findRecord(tenant.id, this.listFilter).pipe(
            tap((tenant: Tenant) => {
              this.tenant = tenant;
              this.setUsers();
            })
          );
        })
      );
  }

  isCurrentUserAllowed(): boolean {
    const userRoles = this.sessionService.user.global_roles;
    return this.tenant
      && (
        userRoles.includes('admin')
        || (userRoles.includes('support') && !this.tenant.secured)
        || (
          userRoles.includes('sales') && !this.tenant.secured
          && (this.tenant.client_lifecycle === 'fast_path_to_value' || this.tenant.client_lifecycle === 'trial')
        )
        || this.sessionService.user.tenants
            .find(tenant => Number(tenant.id) === Number(this.tenant.id))
            ?.permissions.length > 0
      );
  }

  getUserOptions(emptyLabel = '', idAsString = false, permission?: string) {
    const options = [
      {
        label: emptyLabel || '',
        value: null
      }
    ];

    if (this.tenant && this.tenant.roles) {
      for (const role of this.tenant.roles) {
        if (role && role.user) {
          if (!permission || (permission && role.permissions.find(p => p === permission || p === 'admin' as Permissions || p === 'owner' as Permissions))) {
            const id = idAsString ? String(role.user.id) : Number(role.user.id);
            options.push({ label: role.user.name, value: id });
          }
        }
      }
    }

    return options;
  }

  getSortedUserOptions(emptyLabel = '', notAssignedOption = false) {
    const options = [{ label: emptyLabel, value: '' }];

    for (const user of this.users) {
      options.push({ label: user.name, value: user.id });
    }

    options.sort((a, b) => {
      const oa = a.label.toLowerCase();
      const ob = b.label.toLowerCase();

      if (oa < ob) {
          return -1;
      }

      if (oa > ob) {
          return 1;
      }

      return 0;
    });

    if (notAssignedOption) {
      options.splice(1, 0 , { label: '<' + $localize `:@@common.unassigned:unassigned` + '>', value: 'blank_true' });
    }

    return options;
  }

  getUserById(id): User {
    return this.users.find((user: User) => user.id === String(id));
  }

  getUserName(id): string {
    return this.users.find(user => user.id === String(id))?.name;
  }

  private setUsers() {
    this.users = [];
    this.plainUsers = [];

    if (this.tenant?.roles) {
      for (const role of this.tenant.roles) {
        if (role?.user && role.user.id) {
          this.users.push(role.user);
          this.plainUsers.push(role.user.plainModel);
        }
      }
    }
  }
}
