import { differenceInDays, isAfter, isSameDay, isSameMonth, isSameYear, parse } from 'date-fns';
import { Attribute, BelongsTo, HasMany, Model } from './decorators';
import { BaseApiModel } from './base';
import { ActiveStorageAttachmentType, InviteType, RoleType, TenantType } from '@parashift/shared/types';
import { Invite } from './invite.model';
import { Role } from './role.model';
import { ActiveStorageAttachment } from './active-storage-attachment.model';

const Status = {
  '': '',
  pending: $localize `:@@common.pending:Pending` as 'pending',
  deletion_in_progress: $localize `:@@common.deletion_in_progress:Deletion in progress` as 'deletion_in_progress',
  'deletion-in-progress': $localize `:@@common.deletion_in_progress:Deletion in progress` as 'deletion-in-progress',
};
type Status = (typeof Status)[keyof typeof Status];
export { Status };

export interface BrandThemeColors {
  primaryColor: string;
  secondaryColor: string;
}

const Environment = {
  '': '',
  development: $localize `:@@common.environment.development:Development` as 'development',
  integration: $localize `:@@common.environment.integration:Integration` as 'integration',
  production: $localize `:@@common.environment.production:Production` as 'production'
}
type Environment = (typeof Environment)[keyof typeof Environment];
export { Environment };

const ClientLifecycle = {
  trial: $localize `:@@common.client_lifecycle.trial:Trial` as 'trial',
  fast_path_to_value: $localize `:@@common.client_lifecycle.fast_path_to_value:Fast Path to Value` as 'fast_path_to_value',
  client: $localize `:@@common.client_lifecycle.client:Client` as 'client',
  internal: $localize `:@@common.client_lifecycle.internal:Internal` as 'internal',
  cancelled: $localize `:@@common.client_lifecycle.cancelled:Cancelled` as 'cancelled',
}
type ClientLifecycle = (typeof ClientLifecycle)[keyof typeof ClientLifecycle];
export { ClientLifecycle };

const BillingStatus = {
  billed: $localize `:@@common.billing_status.billed:billed` as 'billed',
  free: $localize `:@@common.billing_status.free:free` as 'free',
}
type BillingStatus = (typeof BillingStatus)[keyof typeof BillingStatus];
export { BillingStatus };

export enum ProcessingPriority {
  fast = 1,
  normal,
  slow
}

export interface TenantPlainModel {
  id: string;
  agent_tenant_id: string | number;
  agent: boolean;
  billing_status: BillingStatus;
  brand_theme: BrandThemeColors;
  client_lifecycle: ClientLifecycle;
  contract_amount: number;
  contract_currency: 'CHF' | 'EUR' | 'USD';
  contract_documents_link: string;
  contract_end_date: string;
  contract_sla_documents_link: string;
  contract_sla_version: string;
  contract_standard_sla: boolean;
  contract_start_date: string;
  contract_yearly_document_quota: number;
  created_at: string;
  disabled: boolean;
  email_token: string;
  embedded_login_enabled: boolean;
  environment: Environment;
  expires_on: string;
  feature_flags: string[];
  name: string;
  organization_id: number;
  partner_id: number;
  permissions: Permissions[];
  phone: string;
  processing_priority: ProcessingPriority;
  secured: boolean;
  slack_channel: string;
  status: Status;
  super_tenant_id: number;
  updated_at: string;

  invites: (Invite | Partial<Invite>)[];
  roles: (Role | Partial<Role>)[];
  logo_attachment: (ActiveStorageAttachment | Partial<ActiveStorageAttachment>);
}

@Model({ type: TenantType })
export class Tenant extends BaseApiModel<TenantPlainModel> {

  @Attribute()
  agent: boolean;

  @Attribute()
  agent_tenant_id: string | number;

  @Attribute()
  billing_status: BillingStatus;

  @Attribute()
  client_lifecycle: ClientLifecycle;

  @Attribute()
  contract_amount: number;

  @Attribute()
  contract_currency: 'CHF' | 'EUR' | 'USD';

  @Attribute()
  contract_documents_link: string;

  @Attribute()
  contract_end_date: string;

  @Attribute()
  contract_sla_documents_link: string;

  @Attribute()
  contract_sla_version: string;

  @Attribute()
  contract_standard_sla: boolean;

  @Attribute()
  contract_start_date: string;

  @Attribute()
  contract_yearly_document_quota: number;

  @Attribute({ readonly: true })
  created_at: string;

  @Attribute()
  brand_theme: BrandThemeColors;

  @Attribute()
  disabled: boolean;

  @Attribute()
  email_token: string;

  @Attribute()
  embedded_login_enabled: boolean;

  @Attribute()
  environment: Environment;

  @Attribute()
  expires_on: string;

  @Attribute()
  feature_flags: string[];

  @Attribute()
  name: string;

  @Attribute()
  organization_id: number;

  @Attribute()
  partner_id: number;

  @Attribute()
  permissions: Permissions[];

  @Attribute()
  processing_priority: ProcessingPriority;

  @Attribute()
  status: Status;

  @Attribute()
  super_tenant_id: number;

  @Attribute({ readonly: true })
  updated_at: string;

  @Attribute()
  phone: string;

  @Attribute()
  secured: boolean;

  @Attribute()
  slack_channel: string;

  // Includes / Relations

  @HasMany({ class: InviteType, sidepostable: false })
  invites: Invite[];

  @HasMany({ class: RoleType, sidepostable: true })
  roles: Role[];

  @BelongsTo({ class: ActiveStorageAttachmentType, sidepostable: true })
  logo_attachment: (ActiveStorageAttachment | Partial<ActiveStorageAttachment>);

  getRolesWithUsers(): Role[] {
    if (this.roles && Array.isArray(this.roles)) {
      return this.roles.filter(role => role.user && role.user.id);
    }

    return [];
  }

  getRolesWithApiKeys(): Role[] {
    if (this.roles && Array.isArray(this.roles)) {
      return this.roles.filter(role => role.api_key && role.api_key.id);
    }

    return [];
  }

  get searchable() {
    return this.name + ' (#' + this.id + ')';
  }

  isTrial(): boolean {
    return false;
    // For now we disable onboarding because it is broken due to removed crm endpoint
    return this.client_lifecycle === 'trial' && !!this.expires_on;
  }

  isExpired(): boolean {
    if (!this.expires_on) {
      return false;
    }
    const expiresOn = parse(this.expires_on, 'yyyy-MM-dd', new Date());
    const now = new Date();
    return isAfter(now, expiresOn);
  }

  expirationDays(): number {
    if (!this.expires_on) {
      return undefined;
    }
    const expiresOn = parse(this.expires_on, 'yyyy-MM-dd', new Date());
    const now = new Date();

    if ((isSameDay(expiresOn, now) && isSameMonth(expiresOn, now) && isSameYear(expiresOn, now)) || isAfter(now, expiresOn)) {
      return differenceInDays(now, expiresOn);
    }
    return differenceInDays(expiresOn, now);
  }
}
